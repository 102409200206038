export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      redirect: { name: 'Nuevo Registro' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },
        {
          path: 'usuarios',
          meta: { label: 'Usuarios', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de Usuarios',
              path: '',
              component: () => import('@/views/Usuarios/UsuariosTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Usuario',
              component: () => import('@/views/Usuarios/UsuariosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: () => import('@/views/Usuarios/UsuariosEdit'),
            },
            {
              path: 'clearpass/:id',
              name: 'Blanquear Usuario',
              component: () => import('@/views/Usuarios/UsuariosClearPass'),
            },
          ],
        },
        {
          path: 'proveedores',
          meta: { label: 'Proveedores', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de proveedores',
              path: '',
              component: () => import('@/views/Proveedores/ProveedoresTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Proveedor',
              component: () => import('@/views/Proveedores/ProveedoresCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Proveedor',
              component: () => import('@/views/Proveedores/ProveedoresEdit'),
            },
          ],
        },
        {
          path: 'empresas',
          meta: { label: 'Empresas', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Listado de empresas',
              path: '',
              component: () => import('@/views/Empresas/EmpresasTable'),
            },
            {
              path: 'create',
              name: 'Nuevo Empresas',
              component: () => import('@/views/Empresas/EmpresasCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Empresas',
              component: () => import('@/views/Empresas/EmpresasEdit'),
            },
          ],
        },
        {
          path: 'sucursales',
          meta: { label: 'Sucursales', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista Sucursales de empresas',
              path: '',
              component: () => import('@/views/Empresas/SucursalesTable'),
            },
          ],
        },
        {
          path: 'allcajas',
          meta: { label: 'Registros' },
          name: 'Registros completos',
          component: () => import('@/views/Registros/RegistrosAllTable'),
        },
        {
          path: 'movimientos',
          meta: { label: 'Movimientos', is_admin: true },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              name: 'Lista de Movimientos',
              path: '',
              component: () => import('@/views/Movimientos/MovimientosTable'),
            },
            /* {
              path: 'create',
              name: 'Nuevo Usuario',
              component: () => import('@/views/Movimientos/MovimientosCreate'),
            },
            {
              path: 'edit/:id',
              name: 'Editar Usuario',
              component: () => import('@/views/Movimientos/MovimientosEdit'),
            }, */
          ],
        },
        {
          path: 'registro',
          name: 'Nuevo Registro',
          component: () => import('@/views/Registros/RegistrosCreate'),
        },
        {
          path: 'caja',
          meta: { label: 'Registros' },
          name: 'Tabla de Registros',
          component: () => import('@/views/Registros/RegistrosTable'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Auth/Logout'),
    },
  ],
}
