import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import rutas from '@/router/_config'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
})

function configRoutes() {
  return rutas.listado
}

router.beforeEach((to, from, next) => {
  // Check login
  if (to.path === '/login' && store.getters.authStatus) {
    next({ name: 'Nuevo Registro' })
    return
  }
  // Check Auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.authStatus) {
      // Check User
      if (to.matched.some((record) => record.meta.is_user)) {
        if (store.getters.getRole > 0) {
          next()
          return
        } else {
          next({ name: 'Nuevo Registro' })
          return
        }
      }
      // Check Admin
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (store.getters.getRole > 1) {
          next()
          return
        } else {
          next({ name: 'Nuevo Registro' })
          return
        }
      }
    } else {
      next({ name: 'login' })
      return
    }
  }
  next()
})
export default router
